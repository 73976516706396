<template>
    <div align="left" justify="center" class="pa-3">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <h1
            v-bind:style="{ color: primaryColorFont }"
            class="ml-3 mt-3 font-weight-light"
        >
            Relatórios | Detalhado
        </h1>
        <v-card class="pa-3 my-2">
            <v-row class="pa-3">
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Código do Título"
                                splaceholder="Titulo"
                                v-model="filtros.ds_nosso_numero"
                            ></v-text-field>
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span>Informe o Código do Título</span>
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-text-field
                        label="CPF/CNPJ"
                        splaceholder="Documento"
                        v-model="filtros.nu_identificacao_devedor"
                    ></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span
                            >Informe o CPF/CNPJ, ou apenas seus números
                            iniciais</span
                        >
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-text-field
                        label="Nome"
                        splaceholder="Nome"
                        v-model="filtros.nm_devedor"
                    ></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span
                            >Informe o Nome que deseja encontrar, por exemplo:
                            Ricardo</span
                        >
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-select
                        v-model="filtros.cd_status"
                        :items="listaSituacoes"
                        label="Status"
                        item-text="nm_status"
                        item-value="cd_status"
                        :return-object="true"
                    ></v-select>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span>Selecione um tipo de status desejado</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row class="pa-3">
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-menu
                        v-model="menuDataVencimentoRange"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedDateVencimentoFormattedRange"
                                label="Data de vencimento"
                                prepend-icon="event"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="filtros.dt_vencimento_range"
                            range
                            color="primary"
                            @input="menuDataVencimentoRange"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span
                            >Filtrar títulos por data de vencimento, selecione a
                            data inicial em seguida a data final.
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-menu
                        v-model="menuDataCadastroRange"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedDateCadastroFormattedRange"
                                label="Data de envio"
                                prepend-icon="event"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filtros.dt_cadastro_range"
                            range
                            color="primary"
                            @input="menuDataCadastroRange"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span
                            >Filtrar títulos por data de envio, selecione a data
                            inicial, e seguida a data final</span
                        >
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-menu
                        v-model="menuDataAtualizacaoRange"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedDateAtualizacaoFormattedRange"
                                label="Data de atualização"
                                prepend-icon="event"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filtros.dt_atualizacao_range"
                            range
                            color="primary"
                            @input="menuDataAtualizacaoRange"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span
                            >Filtrar títulos por data de atualização, selecione
                            a data inicial em seguida a data final.
                        </span>
                    </v-tooltip>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-select
                        v-model="filtros.cd_uf_devedor"
                        :items="listaUfs"
                        label="UF"
                        :return-object="true"
                    ></v-select>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                class="pl-2"
                            >
                                help
                            </v-icon>
                        </template>
                        <span>Selecione um Estado/UF</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row class="pa-3">
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-select
                        v-model="filtros.cd_carteira"
                        :items="listaCarteiras"
                        label="Carteiras"
                        :return-object="true"
                    ></v-select>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class="pl-2"
                                color="grey lighten-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                help
                            </v-icon>
                        </template>
                        <span>Selecione uma carteira </span>
                    </v-tooltip>
                </v-col>
                <v-col cols="4" xs="6" sm="6" md="3" lg="3">
                    <div class="d-flex flex-row" v-if="!isUsuarioAgrupador">
                        <v-select
                            :disabled="
                                loadingAgrupador || usuarioAgrupador != null
                            "
                            :loading="loadingAgrupador"
                            :items="agrupadores"
                            v-model="filtros.ds_agrupador"
                            label="Agrupador"
                            item-text="nm_agrupador"
                            item-value="ds_agrupador"
                            data-cy="agrupador"
                        />
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey lighten-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="pl-2"
                                >
                                    help
                                </v-icon>
                            </template>
                            <span
                                >{{
                                    usuarioAgrupador != null
                                        ? 'Agrupador definido automaticamente.'
                                        : 'Selecione o agrupador que deseja filtrar'
                                }}
                            </span>
                        </v-tooltip>
                    </div>
                </v-col>
                <v-col
                    cols="4"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row justify-center"
                >
                    <v-checkbox
                        class="mb-2 d-flex justify-center"
                        color="primary"
                        v-model="revisao"
                        label="Revisão"
                    ></v-checkbox>
                </v-col>
                <v-col
                    cols="6"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="d-flex flex-row justify-center"
                >
                    <v-btn
                        class="mt-3 d-flex justify-center"
                        width="200"
                        color="primary"
                        :disabled="false"
                        :loading="loadingExport"
                        @click="exportar()"
                    >
                        Excel Detalhado
                        <v-icon class="ml-2">cloud_download</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="modelReceberRelatorioEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-form
                        ref="formEmail"
                        v-model="validEmail"
                        @keyup.native.enter="checkformSubmit()"
                    >
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="E-mail"
                            type="email"
                            v-model="emailReport"
                            :rules="emailRules"
                            required
                            data-cy="signinEmailField"
                        ></v-text-field>
                    </v-form>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarEmailPdf()"
                        :disabled="checkForm()"
                        data-cy="signinSubmitBtn"
                    >
                        Enviar
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mostraConfirmacaoEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        v-show="mostraConfirmacaoEmail"
                        @click="mostraConfirmacaoEmail = false"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
import * as Formatter from '@/helpers/Formatter';
import {
    listarSituacoesTitulo,
    listarAgrupadores
} from '@/helpers/Utilitarios';
import Loading from 'vue-loading-overlay';

export default {
    name: 'PDFConsulta',
    components: {
        Loading
    },
    data() {
        return {
            menuDataVencimentoRange: false,
            menuDataCadastroRange: false,
            menuDataAtualizacaoRange: false,
            loadingInicial: false,
            revisao: false,
            loadingExport: false,
            emailReport: '',
            modelReceberRelatorioEmail: false,
            listaUfs: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            filtros: {
                cd_status: null,
                nm_devedor: null,
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                ds_agrupador: null,
                apontamento_nu_protocolo: null,
                dt_vencimento_range: [],
                dt_cadastro_range: [],
                dt_atualizacao_range: [],
                dt_vencimento_range_fmt: null,
                dt_atualizacao_range_fmt: null,
                dt_cadastro_range_fmt: null
            },
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            validEmail: false,
            mostraConfirmacaoEmail: false,
            primaryColorFont: '#757575',
            loadingAgrupador: false
        };
    },
    watch: {
        'filtros.dt_vencimento_range'() {
            this.filtros.dt_vencimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_vencimento_range[0]),
                Formatter.formatData(this.filtros.dt_vencimento_range[1])
            ];
            if (
                this.filtros.dt_vencimento_range[0] <
                    this.filtros.dt_vencimento_range[1] ||
                this.filtros.dt_vencimento_range[1] === undefined
            ) {
                this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
                this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];
            } else {
                this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[1];
                this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[0];
            }
        },
        'filtros.dt_cadastro_range'() {
            this.filtros.dt_cadastro_range_fmt = [
                Formatter.formatData(this.filtros.dt_cadastro_range[0]),
                Formatter.formatData(this.filtros.dt_cadastro_range[1])
            ];
            if (
                this.filtros.dt_cadastro_range[0] <
                    this.filtros.dt_cadastro_range[1] ||
                this.filtros.dt_cadastro_range[1] === undefined
            ) {
                this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
                this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];
            } else {
                this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[1];
                this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[0];
            }
        },
        'filtros.dt_atualizacao_range'() {
            this.filtros.dt_atualizacao_range_fmt = [
                Formatter.formatData(this.filtros.dt_atualizacao_range[0]),
                Formatter.formatData(this.filtros.dt_atualizacao_range[1])
            ];
            if (
                this.filtros.dt_atualizacao_range[0] <
                    this.filtros.dt_atualizacao_range[1] ||
                this.filtros.dt_atualizacao_range[1] === undefined
            ) {
                this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[0];
                this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[1];
            } else {
                this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[1];
                this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[0];
            }
        }
    },
    computed: {
        computedDateVencimentoFormattedRange() {
            return this.dataVencimento();
        },
        computedDateCadastroFormattedRange() {
            return this.dataCadastro();
        },
        computedDateAtualizacaoFormattedRange() {
            return this.dataAtualizacao();
        },
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        listaCarteiras() {
            return this.$store.getters.Carteiras;
        },
        superUsuario() {
            return this.$store.getters.getSuperUser === 1;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        }
    },
    async mounted() {
        this.filtros.dt_vencimento_de = this.filtros.dt_vencimento_range[0];
        this.filtros.dt_vencimento_ate = this.filtros.dt_vencimento_range[1];

        this.filtros.dt_cadastro_de = this.filtros.dt_cadastro_range[0];
        this.filtros.dt_cadastro_ate = this.filtros.dt_cadastro_range[1];

        this.filtros.dt_atualizacao_de = this.filtros.dt_atualizacao_range[0];
        this.filtros.dt_atualizacao_ate = this.filtros.dt_atualizacao_range[1];

        this.loadingInicial = true;
        await listarSituacoesTitulo(Vue.http, this.$store);
        this.loadingInicial = false;

        if (this.usuarioAgrupador) {
            this.filtros.ds_agrupador = this.usuarioAgrupador;
        }

        let tituloService = new TituloService(Vue.http, this.$store);
        tituloService.listaCarteiras();
        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }

        await this.checarAgrupadores();
    },
    methods: {
        listarAgrupadores,
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                console.log(e.data.mensagem);
                // TODO incluir chamada de snackbar quando REC-2805 for concluída
            } finally {
                this.loadingAgrupador = false;
            }
        },
        dataVencimento() {
            this.filtros.dt_vencimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_vencimento_range[0]),
                Formatter.formatData(this.filtros.dt_vencimento_range[1])
            ];
            if (this.filtros.dt_vencimento_range_fmt[1] == null) {
                return this.filtros.dt_vencimento_range_fmt[0];
            } else if (
                this.filtros.dt_vencimento_range_fmt[0] >
                this.filtros.dt_vencimento_range_fmt[1]
            ) {
                this.filtros.dt_vencimento_range_fmt = this.filtros.dt_vencimento_range_fmt.reverse();
                return this.filtros.dt_vencimento_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_vencimento_range_fmt.join(' - ');
            }
        },
        dataCadastro() {
            this.filtros.dt_cadastro_range_fmt = [
                Formatter.formatData(this.filtros.dt_cadastro_range[0]),
                Formatter.formatData(this.filtros.dt_cadastro_range[1])
            ];
            if (this.filtros.dt_cadastro_range[1] == null) {
                return this.filtros.dt_cadastro_range_fmt[0];
            } else if (
                this.filtros.dt_cadastro_range_fmt[0] >
                this.filtros.dt_cadastro_range_fmt[1]
            ) {
                this.filtros.dt_cadastro_range_fmt = this.filtros.dt_cadastro_range_fmt.reverse();
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_cadastro_range_fmt.join(' - ');
            }
        },
        dataAtualizacao() {
            this.filtros.dt_atualizacao_range_fmt = [
                Formatter.formatData(this.filtros.dt_atualizacao_range[0]),
                Formatter.formatData(this.filtros.dt_atualizacao_range[1])
            ];
            if (this.filtros.dt_atualizacao_range[1] == null) {
                return this.filtros.dt_atualizacao_range_fmt[0];
            } else if (
                this.filtros.dt_atualizacao_range_fmt[0] >
                this.filtros.dt_atualizacao_range_fmt[1]
            ) {
                this.filtros.dt_atualizacao_range_fmt = this.filtros.dt_atualizacao_range_fmt.reverse();
                return this.filtros.dt_atualizacao_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_atualizacao_range_fmt.join(' - ');
            }
        },
        exportar() {
            this.modelReceberRelatorioEmail = true;
        },
        exportarEmailPdf() {
            var emails = [];
            emails[0] = this.emailReport;
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingExport = true;
            if (this.filtros.cd_status) {
                this.filtros.cd_status = this.filtros.cd_status.cd_status;
            }
            tituloService
                .exportarTitulosEmails(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.loadingExport = false;
                    this.modelReceberRelatorioEmail = false;
                    this.mostraConfirmacaoEmail = true;
                })
                .catch(() => {
                    console.log('error occured');
                    this.loadingExport = false;
                });
        },
        checkformSubmit() {
            this.checkForm();
        },
        checkForm() {
            if (this.validEmail) {
                return false;
            }
            return true;
        }
    }
};
</script>
