import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-3", attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        "h1",
        {
          staticClass: "ml-3 mt-3 font-weight-light",
          style: { color: _vm.primaryColorFont }
        },
        [_vm._v(" Relatórios | Detalhado ")]
      ),
      _c(
        VCard,
        { staticClass: "pa-3 my-2" },
        [
          _c(
            VRow,
            { staticClass: "pa-3" },
            [
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(VTextField, {
                                attrs: {
                                  label: "Código do Título",
                                  splaceholder: "Titulo"
                                },
                                model: {
                                  value: _vm.filtros.ds_nosso_numero,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filtros,
                                      "ds_nosso_numero",
                                      $$v
                                    )
                                  },
                                  expression: "filtros.ds_nosso_numero"
                                }
                              }),
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Informe o Código do Título")])]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VTextField, {
                    attrs: { label: "CPF/CNPJ", splaceholder: "Documento" },
                    model: {
                      value: _vm.filtros.nu_identificacao_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "nu_identificacao_devedor", $$v)
                      },
                      expression: "filtros.nu_identificacao_devedor"
                    }
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Informe o CPF/CNPJ, ou apenas seus números iniciais"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VTextField, {
                    attrs: { label: "Nome", splaceholder: "Nome" },
                    model: {
                      value: _vm.filtros.nm_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "nm_devedor", $$v)
                      },
                      expression: "filtros.nm_devedor"
                    }
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Informe o Nome que deseja encontrar, por exemplo: Ricardo"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.listaSituacoes,
                      label: "Status",
                      "item-text": "nm_status",
                      "item-value": "cd_status",
                      "return-object": true
                    },
                    model: {
                      value: _vm.filtros.cd_status,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "cd_status", $$v)
                      },
                      expression: "filtros.cd_status"
                    }
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v("Selecione um tipo de status desejado")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "pa-3" },
            [
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      label: "Data de vencimento",
                                      "prepend-icon": "event",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.computedDateVencimentoFormattedRange,
                                      callback: function($$v) {
                                        _vm.computedDateVencimentoFormattedRange = $$v
                                      },
                                      expression:
                                        "computedDateVencimentoFormattedRange"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataVencimentoRange,
                        callback: function($$v) {
                          _vm.menuDataVencimentoRange = $$v
                        },
                        expression: "menuDataVencimentoRange"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: { range: "", color: "primary", locale: "pt-br" },
                        on: { input: _vm.menuDataVencimentoRange },
                        model: {
                          value: _vm.filtros.dt_vencimento_range,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "dt_vencimento_range", $$v)
                          },
                          expression: "filtros.dt_vencimento_range"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Filtrar títulos por data de vencimento, selecione a data inicial em seguida a data final. "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      label: "Data de envio",
                                      "prepend-icon": "event",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.computedDateCadastroFormattedRange,
                                      callback: function($$v) {
                                        _vm.computedDateCadastroFormattedRange = $$v
                                      },
                                      expression:
                                        "computedDateCadastroFormattedRange"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataCadastroRange,
                        callback: function($$v) {
                          _vm.menuDataCadastroRange = $$v
                        },
                        expression: "menuDataCadastroRange"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: { range: "", color: "primary", locale: "pt-br" },
                        on: { input: _vm.menuDataCadastroRange },
                        model: {
                          value: _vm.filtros.dt_cadastro_range,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "dt_cadastro_range", $$v)
                          },
                          expression: "filtros.dt_cadastro_range"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Filtrar títulos por data de envio, selecione a data inicial, e seguida a data final"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      label: "Data de atualização",
                                      "prepend-icon": "event",
                                      "persistent-hint": "",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.computedDateAtualizacaoFormattedRange,
                                      callback: function($$v) {
                                        _vm.computedDateAtualizacaoFormattedRange = $$v
                                      },
                                      expression:
                                        "computedDateAtualizacaoFormattedRange"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.menuDataAtualizacaoRange,
                        callback: function($$v) {
                          _vm.menuDataAtualizacaoRange = $$v
                        },
                        expression: "menuDataAtualizacaoRange"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: { range: "", color: "primary", locale: "pt-br" },
                        on: { input: _vm.menuDataAtualizacaoRange },
                        model: {
                          value: _vm.filtros.dt_atualizacao_range,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "dt_atualizacao_range", $$v)
                          },
                          expression: "filtros.dt_atualizacao_range"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Filtrar títulos por data de atualização, selecione a data inicial em seguida a data final. "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.listaUfs,
                      label: "UF",
                      "return-object": true
                    },
                    model: {
                      value: _vm.filtros.cd_uf_devedor,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "cd_uf_devedor", $$v)
                      },
                      expression: "filtros.cd_uf_devedor"
                    }
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Selecione um Estado/UF")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "pa-3" },
            [
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.listaCarteiras,
                      label: "Carteiras",
                      "return-object": true
                    },
                    model: {
                      value: _vm.filtros.cd_carteira,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "cd_carteira", $$v)
                      },
                      expression: "filtros.cd_carteira"
                    }
                  }),
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "pl-2",
                                      attrs: { color: "grey lighten-2" }
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" help ")]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Selecione uma carteira ")])]
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" } },
                [
                  !_vm.isUsuarioAgrupador
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-row" },
                        [
                          _c(VSelect, {
                            attrs: {
                              disabled:
                                _vm.loadingAgrupador ||
                                _vm.usuarioAgrupador != null,
                              loading: _vm.loadingAgrupador,
                              items: _vm.agrupadores,
                              label: "Agrupador",
                              "item-text": "nm_agrupador",
                              "item-value": "ds_agrupador",
                              "data-cy": "agrupador"
                            },
                            model: {
                              value: _vm.filtros.ds_agrupador,
                              callback: function($$v) {
                                _vm.$set(_vm.filtros, "ds_agrupador", $$v)
                              },
                              expression: "filtros.ds_agrupador"
                            }
                          }),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "pl-2",
                                                attrs: {
                                                  color: "grey lighten-2"
                                                }
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" help ")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2953836661
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.usuarioAgrupador != null
                                      ? "Agrupador definido automaticamente."
                                      : "Selecione o agrupador que deseja filtrar"
                                  ) + " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row justify-center",
                  attrs: { cols: "4", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(VCheckbox, {
                    staticClass: "mb-2 d-flex justify-center",
                    attrs: { color: "primary", label: "Revisão" },
                    model: {
                      value: _vm.revisao,
                      callback: function($$v) {
                        _vm.revisao = $$v
                      },
                      expression: "revisao"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "d-flex flex-row justify-center",
                  attrs: { cols: "6", xs: "6", sm: "6", md: "3", lg: "3" }
                },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mt-3 d-flex justify-center",
                      attrs: {
                        width: "200",
                        color: "primary",
                        disabled: false,
                        loading: _vm.loadingExport
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportar()
                        }
                      }
                    },
                    [
                      _vm._v(" Excel Detalhado "),
                      _c(VIcon, { staticClass: "ml-2" }, [
                        _vm._v("cloud_download")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modelReceberRelatorioEmail,
            callback: function($$v) {
              _vm.modelReceberRelatorioEmail = $$v
            },
            expression: "modelReceberRelatorioEmail"
          }
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardText,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório via e-mail")]
                  ),
                  _c(VSpacer),
                  _c(
                    VForm,
                    {
                      ref: "formEmail",
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.checkformSubmit()
                        }
                      },
                      model: {
                        value: _vm.validEmail,
                        callback: function($$v) {
                          _vm.validEmail = $$v
                        },
                        expression: "validEmail"
                      }
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          "prepend-icon": "person",
                          name: "email",
                          label: "E-mail",
                          type: "email",
                          rules: _vm.emailRules,
                          required: "",
                          "data-cy": "signinEmailField"
                        },
                        model: {
                          value: _vm.emailReport,
                          callback: function($$v) {
                            _vm.emailReport = $$v
                          },
                          expression: "emailReport"
                        }
                      })
                    ],
                    1
                  ),
                  _c(VCardSubtitle, [
                    _vm._v("Digite o e-mail onde deseja receber o relatório")
                  ])
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.checkForm(),
                        "data-cy": "signinSubmitBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportarEmailPdf()
                        }
                      }
                    },
                    [
                      _vm._v(" Enviar "),
                      _c(VIcon, { staticClass: "ml-1" }, [
                        _vm._v("chevron_right")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.mostraConfirmacaoEmail,
            callback: function($$v) {
              _vm.mostraConfirmacaoEmail = $$v
            },
            expression: "mostraConfirmacaoEmail"
          }
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardText,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "headline primary--text" },
                    [_vm._v("Relatório solicitado com sucesso.")]
                  ),
                  _c(VSpacer),
                  _c(VCardSubtitle, [
                    _vm._v("O relatório será enviado para o seu e-mail!")
                  ])
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mostraConfirmacaoEmail,
                          expression: "mostraConfirmacaoEmail"
                        }
                      ],
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.mostraConfirmacaoEmail = false
                        }
                      }
                    },
                    [_vm._v("Ok!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }